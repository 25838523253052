import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"
import classNames from "classnames"
import DirectLink from "../../components/common/direct-link/direct-link"
import auth from "../../services/auth"
import util from "../../services/util"

// const host = process.env.GATSBY_FEDIA_DMS_HOST || "localhost"
// const port = process.env.GATSBY_FEDIA_DMS_PORT || 1337
// const baseUrl = `https://${host}:${port}/`

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query PageAboutQuery {
      allStrapiBylaws {
        edges {
          node {
            bylaws {
              thumbnail
              title
              file
            }
          }
        }
      }   

      allStrapiMembers {
        nodes {
          members {
            name
            unit
            avatar
          }
        }
      }
      
      allStrapiAbout {
        edges {
          node {
            title
            intro
            objectivesIntro
            objectives {
              description
            }
            teamTitle
            teamDescription
            teamDescription2
            teamMembersHeading
          }
        }
      }
    }
  `)
  return (
    <WebsiteLayout>
      <SEO title={data.allStrapiAbout.edges[0].node.title} />
      {/* <h1>{data.allStrapiAbout.edges[0].node.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: data.allStrapiAbout.edges[0].node.content,
        }}
      /> */}
      <div className={styles.wrapper}>
        <div className={styles.ourMission}>
          <div className={styles.title}>
            <div className={styles.container}>
              <h1>{data.allStrapiAbout.edges[0].node.title}</h1>
              <p>{data.allStrapiAbout.edges[0].node.intro}</p>
            </div>
          </div>

          <div className={styles.heroImage}></div>
          <div className={styles.achieve}>
            <div className={styles.achieveTitle}>
              <h3>{data.allStrapiAbout.edges[0].node.objectivesIntro}</h3>
            </div>
            <div className={styles.achieveList}>
              <ul>
                {data.allStrapiAbout.edges[0].node.objectives.map(o => (
                  <li key={o.id}>{o.description}</li>
                ))}
              </ul>
              <DirectLink
                to="/membership"
                text="See our Membership Plan"
                className={styles.membershipPlan}
              />
            </div>
          </div>
        </div>
        <div className={styles.meetOurTeam}>
          <div className={styles.container}>
            {/* <h1>Meet Our Team</h1> */}
            <h1>{data.allStrapiAbout.edges[0].node.teamTitle}</h1>
            <p>
              {/* The nine-member Board of Directors is comprised of three
              representatives from the Alberta government (the old Alberta
              Forest Service and its successor organizations), three members
              from the forest industry and three members at large.  */}
              {data.allStrapiAbout.edges[0].node.teamDescription}
              <br />
              <br />
              {/* The Executive is made up of President Peter Murphy, Vice President
              (currently vacant), Secretary/Treasurer Bruce Mayer. */}
              {data.allStrapiAbout.edges[0].node.teamDescription2}
            </p>

            {/* <h4>The current Board of Directors are (as of March 21, 2018):</h4> */}
            <h4>{data.allStrapiAbout.edges[0].node.teamMembersHeading}</h4>
          </div>
          <div className={styles.teamGroup}>
            <ul className={classNames(styles.team1, styles.team)}>
              {data.allStrapiMembers.nodes[0].members.slice(0, 4).map(m => (
                <li key={m.name}>
                  <div className={styles.avatar}>
                    {/* NOTE: this image is a placeholder */}
                    <img
                      src={`${util.baseUrl()}${m.avatar}`}
                      alt={m.name}
                    />
                  </div>
                  <h4 className={styles.name}>{m.name}</h4>
                  <div className={styles.unit}>{m.unit}</div>
                </li>
              ))}
            </ul>
            <ul className={classNames(styles.team2, styles.team)}>
              {data.allStrapiMembers.nodes[0].members.slice(4).map(m => (
                <li key={m.name}>
                  <div className={styles.avatar}>
                    {/* NOTE: this image is a placeholder */}
                    <img
                      src={`${util.baseUrl()}${m.avatar}`}
                      alt={m.name}
                    />
                  </div>
                  <h4 className={styles.name}>{m.name}</h4>
                  <div className={styles.unit}>{m.unit}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.byLaws}>
          <div className={styles.container}>
            <h1>Bylaws</h1>
            <ul className={styles.certificates}>
              {data.allStrapiBylaws.edges[0].node.bylaws.map(b => (
                <li>
                  <div className={styles.thumbnail}>
                    <img
                      src={`${util.baseUrl()}${b.thumbnail}`}
                      alt={b.title}
                    />
                  </div>
                  <h4 className={styles.title}>{b.title}</h4>
                  <DirectLink
                    className={styles.action}
                    linkTo={`${util.baseUrl()}${b.file}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </WebsiteLayout>
  )
}

export default AboutPage
